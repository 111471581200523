<template>
  <div>
    <slot :openFirstImage="openFirstImage">
      <div class="image-grid">
        <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            @click="openFullScreen(index)"
            :alt="`Image ${index + 1}`"
            class="preview-image"
        />
      </div>
    </slot>
    <div v-if="isFullScreen" class="fullscreen-overlay text-light" @click="closeFullScreen">
      <div class="fullscreen-container" ref="fullscreenContainer" @wheel="zoomImage" @mousedown="startDragging">
        <img
            ref="fullscreenImage"
            @click.stop="null"
            :src="images[currentIndex]"
            :alt="`Full screen image ${currentIndex + 1}`"
            class="fullscreen-image"
            :style="{
    transform: `scale(${zoom}) translate(${translateX}px, ${translateY}px) rotate(${rotation}deg)`
  }"
        />
      </div>
      <div class="navigation">
        <slot name="navigation" :nextImage="nextImage" :prevImage="prevImage" :currentIndex="currentIndex" :rotateImage="rotateImage"
              :imgPath="images[currentIndex]">
          <slot name="prevImage" :prevImage="prevImage">
            <button @click.stop="prevImage" class="nav-button">Previous</button>
          </slot>
          <slot name="rotate" :prevImage="prevImage">
            <button @click.stop="rotateImage" class="nav-button">Rotate</button>
          </slot>
          <slot name="nextImage" :nextImage="nextImage">
            <button @click.stop="nextImage" class="nav-button">Next</button>
          </slot>
        </slot>
      </div>
      <div class="image-counter">{{ currentIndex + 1 }} / {{ images.length }}</div>
    </div>
  </div>
</template>

<script lang="js">
import {onMounted, onUnmounted, ref, watch} from 'vue';

export default {
  name: 'FullScreenImageGallery',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const isFullScreen = ref(false);
    const currentIndex = ref(0);
    const zoom = ref(1);
    const translateX = ref(0);
    const translateY = ref(0);
    const isDragging = ref(false);
    const lastMouseY = ref(0);
    const rotation = ref(0);

    const openFullScreen = (index) => {
      currentIndex.value = index;
      isFullScreen.value = true;
      resetZoomAndPosition();
    };

    const rotateImage = () => {
      rotation.value = (rotation.value + 90) % 360; // Rotate in 90-degree increments
    };

    const closeFullScreen = () => {
      isFullScreen.value = false;
    };

    const nextImage = (event) => {
      if (event) event.stopPropagation();
      currentIndex.value = (currentIndex.value + 1) % props.images.length;
      resetZoomAndPosition();
    };

    const prevImage = (event) => {
      if (event) event.stopPropagation();
      currentIndex.value = (currentIndex.value - 1 + props.images.length) % props.images.length;
      resetZoomAndPosition();
    };

    const openFirstImage = () => {
      if (props.images.length > 0) {
        openFullScreen(0);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isFullScreen.value) {
        closeFullScreen();
      }
    };

    const zoomImage = (event) => {
      event.preventDefault();
      zoom.value += event.deltaY * -0.01;
      zoom.value = Math.min(Math.max(1, zoom.value), 4); // Set zoom limits (min: 1, max: 4)
    };

    const startDragging = (event) => {
      if (zoom.value > 1) {
        event.preventDefault();
        isDragging.value = true;
        lastMouseY.value = event.clientY;

        window.addEventListener('mousemove', dragImage);
        window.addEventListener('mouseup', stopDragging);
      }
    };

    const dragImage = (event) => {
      if (isDragging.value && zoom.value > 1) {
        const deltaY = event.clientY - lastMouseY.value;
        translateY.value += deltaY;
        lastMouseY.value = event.clientY;
      }
    };


    const stopDragging = () => {
      isDragging.value = false;
      window.removeEventListener('mousemove', dragImage);
      window.removeEventListener('mouseup', stopDragging);
    };

    const resetZoomAndPosition = () => {
      zoom.value = 1;
      translateX.value = 0;
      translateY.value = 0;
    };

    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown);
    });

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown);
    });

    watch(() => props.images, () => {
      currentIndex.value = 0;
    }, {
      immediate: true
    });


    watch(zoom, (newZoom) => {
      if (newZoom === 1) {
        translateY.value = 0; // Reset Y-axis translation
      }
    });

    return {
      isFullScreen,
      currentIndex,
      openFullScreen,
      closeFullScreen,
      nextImage,
      prevImage,
      openFirstImage,
      zoom,
      translateX,
      translateY,
      startDragging,
      zoomImage,
      rotation,
      rotateImage
    };
  }
};
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.preview-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.fullscreen-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  cursor: grab;
}

.fullscreen-image:active {
  cursor: grabbing;
}

.navigation {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.nav-button {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  cursor: pointer;
}

.image-counter {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
}
</style>
